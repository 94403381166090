import request from '@/utils/request'


// 查询短视频列表
export function listVideo(query) {
  return request({
    url: '/video/video/list',
    method: 'get',
    params: query
  })
}

// 查询短视频分页
export function pageVideo(query) {
  return request({
    url: '/video/video/page',
    method: 'get',
    params: query
  })
}

// 查询短视频详细
export function getVideo(data) {
  return request({
    url: '/video/video/detail',
    method: 'get',
    params: data
  })
}

// 新增短视频
export function addVideo(data) {
  return request({
    url: '/video/video/add',
    method: 'post',
    data: data
  })
}

// 修改短视频
export function updateVideo(data) {
  return request({
    url: '/video/video/edit',
    method: 'post',
    data: data
  })
}

// 删除短视频
export function delVideo(data) {
  return request({
    url: '/video/video/delete',
    method: 'post',
    data: data
  })
}
