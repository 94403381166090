<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('视频评价.视频编号')" prop="videoId">
                <a-input v-model="queryParam.videoId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('视频评价.视频编号')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('通用.文本.创建开始时间')" prop="createBeginTime">
                <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"
                     :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('通用.文本.创建结束时间')" prop="createEndTime">
                <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"
                     :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('视频评价.被评价的人的userid')" prop="userId">
                <a-input v-model="queryParam.userId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('视频评价.被评价的人的userid')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('通用.文本.创建开始时间')" prop="createBeginTime">
                <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"
                     :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('通用.文本.创建结束时间')" prop="createEndTime">
                <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"
                     :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('视频评价.评价用户编号')" prop="discussUserId">
                  <a-input v-model="queryParam.discussUserId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('视频评价.评价用户编号')" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('通用.文本.创建开始时间')" prop="createBeginTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"
                     :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('通用.文本.创建结束时间')" prop="createEndTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"
                     :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('通用.文本.创建开始时间')" prop="createBeginTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"
                     :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('通用.文本.创建结束时间')" prop="createEndTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"
                     :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('视频评价.是否包含敏感词0：不包含 1：包含')" prop="isWordsPass">
                  <a-input v-model="queryParam.isWordsPass" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('视频评价.是否包含敏感词0：不包含 1：包含')" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('通用.文本.创建开始时间')" prop="createBeginTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"
                     :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('通用.文本.创建结束时间')" prop="createEndTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"
                     :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('视频评价.评价状态  0：审核未通过 1：审核通过')" prop="discussState">
                  <a-input v-model="queryParam.discussState" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('视频评价.评价状态  0：审核未通过 1：审核通过')" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('通用.文本.创建开始时间')" prop="createBeginTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"
                     :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('通用.文本.创建结束时间')" prop="createEndTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"
                     :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('视频评价.回复数量')" prop="replyNum">
                  <a-input v-model="queryParam.replyNum" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('视频评价.回复数量')" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('通用.文本.创建开始时间')" prop="createBeginTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"
                     :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('通用.文本.创建结束时间')" prop="createEndTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"
                     :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('视频评价.回复的评论id')" prop="replyDiscussId">
                  <a-input v-model="queryParam.replyDiscussId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('视频评价.回复的评论id')" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('通用.文本.创建开始时间')" prop="createBeginTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"
                     :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('通用.文本.创建结束时间')" prop="createEndTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"
                     :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? $t('通用.按钮.收起') : $t('通用.按钮.展开') }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['zb:discuss:add']">
          <a-icon type="plus" />{{$t('通用.按钮.新增')}}
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['zb:discuss:edit']">
          <a-icon type="edit" />{{$t('通用.按钮.修改')}}
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['zb:discuss:remove']">
          <a-icon type="delete" />{{$t('通用.按钮.删除')}}
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['zb:discuss:export']">
          <a-icon type="download" />{{$t('通用.按钮.导出')}}
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />

      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['zb:discuss:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['zb:discuss:edit']">
             <a-icon type="edit" />{{$t('通用.按钮.修改')}}
          </a>
          <a-divider type="vertical" v-hasPermi="['zb:discuss:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['zb:discuss:remove']">
            <a-icon type="delete" />{{$t('通用.按钮.删除')}}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageDiscuss,listDiscuss, delDiscuss } from '@/api/discuss/discuss.js'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import moment from 'moment';
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'DiscussList',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo : {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        videoId: undefined,
        userId: undefined,
        discussUserId: undefined,
        discussContent: undefined,
        isWordsPass: undefined,
        discussState: undefined,
        replyNum: undefined,
        replyDiscussId: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('视频评价.主键id'),
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('视频评价.视频编号'),
          dataIndex: 'videoId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('视频评价.被评价的人的userid'),
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('视频评价.评价用户编号'),
          dataIndex: 'discussUserId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('视频评价.评价内容'),
          dataIndex: 'discussContent',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('视频评价.是否包含敏感词0：不包含 1：包含'),
          dataIndex: 'isWordsPass',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('视频评价.评价状态  0：审核未通过 1：审核通过'),
          dataIndex: 'discussState',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('视频评价.'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('视频评价.回复数量'),
          dataIndex: 'replyNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('视频评价.回复的评论id'),
          dataIndex: 'replyDiscussId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('视频评价.备注'),
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    moment,

    show(record){

      this.queryParam = {};
      this.videoId = record.id;
      this.queryParam = {
        videoId: record.id
      }
      this.visible = true;
      // 当其它模块调用该模块时,调用此方法加载字典数据
      this.getList();

    },
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询视频评价列表 */
    getList () {
      this.loading = true
     pageDiscuss(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        videoId: undefined,
        userId: undefined,
        discussUserId: undefined,
        discussContent: undefined,
        isWordsPass: undefined,
        discussState: undefined,
        replyNum: undefined,
        replyDiscussId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        if (this.queryParam.sortField==='createTime') {
          this.queryParam.sortField= 'id'
        }
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delDiscuss(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              const message = that.$t('通用.文本.删除成功')
              that.$message.success(
                message,
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('zb/discuss/export',
            ...that.queryParam
          , `视频评价_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
